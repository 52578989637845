import "core-js/features/global-this";
import "../Content/js/preloadLogin";
import "../Content/js/system/jquery.cookie.js";
import "../Content/js/bootstrap/bootstrap.js";
import "../Content/js/material/functions.js";
import "../Content/ts/helper/helper";
import "../Content/ts/helper/Helper.Database";
import "../Content/ts/helper/Helper.User";
import "../Content/ts/helper/Helper.String";
import "../Content/ts/helper/Helper.Url";
import "../Content/js/crm/Crm.Token.js";
import "../Content/js/Login.js";
